<template>
  <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]">
    <div class="flex items-center mb-2 justify-between">
      <div class="flex items-center space-x-2">
        <i class="mdi mdi-thermometer text-2xl"></i>
        <select v-model="selectedField" @change="handleFieldChange" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
          <option value="externalTemperature">External Temperature</option>
          <option value="internalTemperature">Internal Temperature</option>
          <option value="internalHumidity">Internal Humidity</option>
          <option value="internalPressure1">Internal Pressure 1</option>
          <option value="internalPressure2">Internal Pressure 2</option>
        </select>
      </div>
      <div class="flex flex-col items-end">
        <select v-model="selectedTimespan" @change="handleTimespanChange" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
          <option value="1d">1 day</option>
          <option value="1w">1 week</option>
          <option value="1m">1 month</option>
          <option value="3m">3 months</option>
          <option value="1y">1 year</option>
          <option value="custom">Custom</option>
        </select>
        <div v-if="selectedTimespan === 'custom'" class="flex items-center space-x-2 mt-2">
          <input 
            type="date" 
            v-model="customStartDate" 
            @change="updateChart"
            class="bg-neutral-700 text-white py-1 px-2 rounded text-xs"
          >
          <input 
            type="date" 
            v-model="customEndDate" 
            @change="updateChart"
            class="bg-neutral-700 text-white py-1 px-2 rounded text-xs"
          >
        </div>
      </div>
    </div>
    <div class="flex-grow relative">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { InfluxDB } from '@influxdata/influxdb-client';
import 'chartjs-adapter-date-fns';

const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ==';
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token });

export default {
  name: 'DeviceTemp',
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      selectedTimespan: '3m',
      temp_bias: 0.55,
      customStartDate: '',
      customEndDate: '',
      selectedField: 'externalTemperature',
      fieldLabels: {
        externalTemperature: 'External Temperature',
        internalTemperature: 'Internal Temperature',
        internalHumidity: 'Internal Humidity',
        internalPressure1: 'Internal Pressure 1',
        internalPressure2: 'Internal Pressure 2',
      },
    };
  },
  computed: {
    selectedFieldLabel() {
      return this.fieldLabels[this.selectedField];
    },
  },
  mounted() {
    this.initializeCustomDates();
    this.updateChart();
  },
  methods: {
    initializeCustomDates() {
      const end = new Date();
      const start = new Date();
      start.setMonth(start.getMonth() - 3);
      this.customEndDate = end.toISOString().split('T')[0];
      this.customStartDate = start.toISOString().split('T')[0];
    },
    handleTimespanChange() {
      if (this.selectedTimespan === 'custom') {
        this.initializeCustomDates();
      }
      this.updateChart();
    },
    handleFieldChange() {
      this.updateChart();
    },
    async fetchData() {
      const queryApi = client.getQueryApi('ReefOS');
      const fluxQuery = this.buildFluxQuery();

      return new Promise((resolve, reject) => {
        const result = [];
        queryApi.queryRows(fluxQuery, {
          next: (row, tableMeta) => {
            const o = tableMeta.toObject(row);
            let value = parseFloat(o._value.toFixed(2));
            if (this.selectedField === 'externalTemperature') {
              value -= parseFloat(this.temp_bias);
            }
            result.push({
              value: value.toFixed(2),
              timestamp: new Date(o._time),
            });
          },
          error: (error) => reject(error),
          complete: () => resolve(result),
        });
      });
    },
    buildFluxQuery() {
      let range, aggregateWindow;
      switch (this.selectedTimespan) {
        case '1d':
          range = '(start: -1d)';
          aggregateWindow = '10m';
          break;
        case '1w':
          range = '(start: -1w)';
          aggregateWindow = '1h';
          break;
        case '1m':
          range = '(start: -1mo)';
          aggregateWindow = '1h';
          break;
        case '3m':
          range = '(start: -3mo)';
          aggregateWindow = '3h';
          break;
        case '1y':
          range = '(start: -1y)';
          aggregateWindow = '1d';
          break;
        case 'custom':
          range = `(start: ${this.customStartDate}T00:00:00Z, stop: ${this.customEndDate}T23:59:59Z)`;
          aggregateWindow = this.getCustomAggregateWindow();
          break;
        default:
          range = '(start: -3mo)';
          aggregateWindow = '3h';
      }

      return `
        from(bucket: "${this.deviceId}")
        |> range${range}
        |> filter(fn: (r) => r._measurement == "sensors" and r._field == "${this.selectedField}")
        |> aggregateWindow(every: ${aggregateWindow}, fn: mean, createEmpty: false)
        |> sort(columns: ["_time"])
        |> yield(name: "mean")
      `;
    },
    async updateChart() {
      try {
        const data = await this.fetchData();

        const dataset = {
          label: this.selectedFieldLabel,
          data: data.map(d => ({ x: d.timestamp, y: d.value })),
          borderWidth: 1.5,
          borderColor: '#27bdf4',
          backgroundColor: this.getGradient(),
          fill: true,
          tension: 0.1,
          pointRadius: 0,
          pointHoverRadius: 0
        };

        this.createChart([dataset]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    createChart(datasets) {
      const ctx = this.$refs.chartCanvas.getContext('2d');

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: 'line',
        data: { datasets },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            title: { display: false },
            tooltip: {
              mode: 'index',
              intersect: false,
              displayColors: false,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              titleColor: 'white',
              bodyColor: 'white',
              titleFont: { weight: 'bold' },
              bodyFont: { size: 14 },
              padding: 10,
              callbacks: {
                title: (tooltipItems) => {
                  const date = new Date(tooltipItems[0].parsed.x);
                  return date.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  });
                },
                label: (context) => {
                  const unit = this.selectedField === 'internalHumidity' ? '%' : '°C';
                  return `${context.parsed.y}${unit}`;
                }
              }
            }
          },
          hover: {
            mode: 'index',
            intersect: false
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: this.getTimeUnit(),
                displayFormats: {
                  hour: 'HH:mm',
                  day: 'MMM d',
                  week: 'MMM d',
                  month: 'MMM yyyy'
                }
              },
              ticks: { 
                color: 'white',
                maxTicksLimit: 12
              },
            },
            y: {
              grid: {
                display: true,
                color: 'rgba(255, 255, 255, 0.2)',
                borderDash: [4, 4],
              },
              ticks: { color: 'white' },
            }
          }
        }
      });
    },
    getGradient() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 250);
      gradient.addColorStop(0, '#27bdf4');
      gradient.addColorStop(1, 'rgba(27, 138, 196, 0.1)');
      return gradient;
    },
    getTimeUnit() {
      switch (this.selectedTimespan) {
        case '1d': return 'hour';
        case '1w': return 'day';
        case '1m': return 'day';
        case '3m': return 'month';
        case '1y': return 'month';
        case 'custom':
          const start = new Date(this.customStartDate);
          const end = new Date(this.customEndDate);
          const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
          if (diffDays <= 1) return 'hour';
          if (diffDays <= 30) return 'day';
          if (diffDays <= 365) return 'month';
          return 'year';
        default: return 'month';
      }
    },
    getCustomAggregateWindow() {
      const start = new Date(this.customStartDate);
      const end = new Date(this.customEndDate);
      const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

      if (diffDays <= 1) return '10m';
      if (diffDays <= 7) return '1h';
      if (diffDays <= 30) return '1h';
      if (diffDays <= 90) return '3h';
      return '1d';
    },
  }
}
</script>
