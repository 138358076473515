<template>
    <header class="p-3 bg-black flex items-center justify-between border-b border-neutral-700 z-3">
        <!-- Left Side -->
        <div class="flex items-center">
            <!-- Company Logo -->
            <img src="@/assets/images/cg.png" alt="Company Logo" class="h-7 w-7 mr-2 ml-1">

            <div class="inline-flex items-center cursor-pointer ml-2" @click="reset">
                <p class="text-white">Reef Manager</p>
            </div>

            <div v-if="!isMobile" class="flex items-center">
                <div class="inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1 self-center" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </div>

                <!-- Branch Dropdown or Corals/Devices Label -->
                <div v-if="isCoralsRoute" class="text-white">
                    Corals
                </div>
                <div v-else-if="isDevicesRoute" class="text-white cursor-pointer" @click="navigateToDevices(false)">
                    Devices
                </div>
                <div v-else class="inline-flex items-center cursor-pointer">
                    <DropdownHeader v-model="selectedBranchId" :options="branches"
                        :placeholder="selectedBranch ? selectedBranch.name : 'Select a Branch'" @input="changeBranch"
                        option-label="name" option-value="id" />
                </div>

                <!-- Device Selection when in Devices route -->
                <template v-if="isDevicesRoute">
                    <div class="inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1 self-center" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                    <div v-if="selectedDevice" class="text-white">
                        {{ selectedDevice }}
                    </div>
                    <div v-else class="inline-flex items-center cursor-pointer">
                        <DropdownHeader v-if="devices.length" v-model="selectedDeviceId" :options="devices"
                            placeholder="Select a Device" @input="changeDevice" option-label="id" option-value="id" />
                    </div>
                </template>

                <!-- Sites Dropdown or Fish Label -->
                <template v-if="selectedBranch && !isCoralsRoute && !isDevicesRoute">
                    <div class="inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1 self-center" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>

                    <div v-if="isFishRoute" class="text-white">
                        Fish
                    </div>
                    <div v-else class="inline-flex items-center cursor-pointer">
                        <DropdownHeader v-model="selectedSiteId" :options="sites"
                            :placeholder="selectedSite ? selectedSite.name : 'All Sites'" @input="changeSite"
                            option-label="name" option-value="id" />
                    </div>
                </template>
            </div>
        </div>
        <!-- Right Side -->
        <div class="flex items-center text-base">
            <div class="relative">
                <button @click="toggleDropdown" class="text-white focus:outline-none">
                    <span class="mdi mdi-dots-vertical h-6 w-6 text-2xl ml-2"></span>
                </button>
                <div v-if="dropdownOpen"
                    class="absolute top-full right-0 mt-2 w-48 bg-black border border-neutral-700 rounded shadow-lg z-20">
                    <!-- Dropdown items -->
                    <div class="py-1">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-neutral-700"
                            @click="navigateToCorals">
                            <span class="mdi mdi-tree mr-2"></span>Manage Corals
                        </a>
                        <a v-if="selectedBranch" href="#"
                            class="block px-4 py-2 text-sm text-white hover:bg-neutral-700" @click="navigateToFish">
                            <span class="mdi mdi-fish mr-2"></span>Manage Fish
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-neutral-700"
                            @click="navigateToDevices(true)">
                            <span class="mdi mdi-video mr-2"></span>Manage Devices
                        </a>
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-neutral-700" @click="logout">
                            <span class="mdi mdi-logout mr-2"></span>Logout
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue';
import DropdownHeader from '@/components/utils/DropdownHeader.vue';
import { db_resto, db_map } from '@/main.js'
import { collection, getDocs } from "firebase/firestore";
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'Header',
    components: {
        Dropdown,
        DropdownHeader,
    },
    data() {
        return {
            branches: [],
            sites: [],
            structures: [],
            cells: [],
            selectedBranchId: '',
            selectedSiteId: '',
            selectedStructureId: '',
            selectedCellId: '',
            dropdownOpen: false,
            showSpecialDropdown: false,
            devices: [],
            selectedDeviceId: '',
        }
    },
    computed: {
        selectedBranch() {
            return this.branches.find(b => b.id === this.selectedBranchId) || null;
        },
        selectedSite() {
            return this.sites.find(s => s.id === this.selectedSiteId) || null;
        },
        selectedStructure() {
            return this.structures.find(s => s.id === this.selectedStructureId) || null;
        },
        selectedCell() {
            return this.cells.find(c => c.id === this.selectedCellId) || null;
        },
        sortedStructures() {
            return [...this.structures].sort((a, b) => a.position - b.position);
        },
        sortedCells() {
            return [...this.cells].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
        },
        isMobile() {
            return window.innerWidth < 768;
        },
        isCoralsRoute() {
            return this.$route.name === 'ManageCorals';
        },
        isFishRoute() {
            return this.$route.name === 'ManageFish';
        },
        isDevicesRoute() {
            return this.$route.name === 'Devices' || this.$route.name === 'Device';
        },
        selectedDevice() {
            return this.$route.params.deviceId;
        }
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.updateFromRoute();
            }
        },
        selectedBranchId() {
            this.fetchSites();
            this.selectedSiteId = '';
            this.selectedStructureId = '';
            this.selectedCellId = '';
            this.structures = [];
            this.cells = [];
        },
        selectedSiteId() {
            if (this.selectedSite && this.selectedSite.type === 'nursery') {
                this.fetchStructures();
            } else if (this.selectedSite && this.selectedSite.type === 'outplant') {
                this.fetchCells();
            } else {
                this.selectedStructureId = '';
                this.structures = [];
                this.selectedCellId = '';
                this.cells = [];
            }
        },
    },
    mounted() {
        this.fetchBranches();
    },
    methods: {
        async fetchBranches() {
            this.branches = [];
            const querySnapshot = await getDocs(collection(db_resto, "Orgs", "coral-gardeners", "Branches"));
            this.branches = querySnapshot.docs
                .filter(doc => doc.id !== 'sandbox')
                .map(doc => ({ id: doc.id, name: doc.data().name }));
        },
        async fetchSites() {
            if (!this.selectedBranchId) {
                this.sites = [];
                return;
            }
            const nurseriesSnapshot = await getDocs(collection(db_resto, `Orgs/coral-gardeners/Branches/${this.selectedBranchId}/Nurseries`));
            const outplantSitesSnapshot = await getDocs(collection(db_resto, `Orgs/coral-gardeners/Branches/${this.selectedBranchId}/OutplantSites`));

            const nurseries = nurseriesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name, type: 'nursery' }));
            const outplantSites = outplantSitesSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name, type: 'outplant' }));

            this.sites = [...nurseries, ...outplantSites];
        },
        async fetchStructures() {
            if (!this.selectedBranchId || !this.selectedSiteId) {
                this.structures = [];
                return;
            }
            const querySnapshot = await getDocs(collection(db_resto, `Orgs/coral-gardeners/Branches/${this.selectedBranchId}/Nurseries/${this.selectedSiteId}/Structures`));
            this.structures = querySnapshot.docs.map(doc => ({
                id: doc.id,
                label: `Structure ${doc.data().position}`,
                ...doc.data()
            }));
        },
        async fetchCells() {
            if (!this.selectedBranchId || !this.selectedSiteId) {
                this.cells = [];
                return;
            }
            
            const querySnapshot = await getDocs(collection(db_resto, `Orgs/coral-gardeners/Branches/${this.selectedBranchId}/OutplantSites/${this.selectedSiteId}/OutplantCells`));
            this.cells = querySnapshot.docs.map(doc => ({ 
                id: doc.id, 
                label: `Cell ${doc.data().name}`,
                ...doc.data() 
            }));
        },
        async fetchDevices() {
            try {
                const devicesRef = collection(db_map, 'Cameras');
                const querySnapshot = await getDocs(devicesRef);

                this.devices = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(device => !device.id.startsWith('debug_'));
            } catch (error) {
                console.error('Error fetching devices:', error);
                this.devices = [];
            }
        },
        changeBranch(branchId) {
            const currentPath = this.$route.fullPath;
            const newPath = `/branch/${branchId}`;
            
            if (currentPath !== newPath) {
                this.$router.push({ name: 'Branch', params: { branchId } });
            }
        },
        changeSite(siteId) {
            const site = this.sites.find(s => s.id === siteId);
            // Only navigate if the selected site is different from the current route
            if (siteId !== this.$route.params.nurseryId && siteId !== this.$route.params.outplantSiteId) {
                if (site.type === 'nursery') {
                    this.$router.push({ name: 'Nursery', params: { branchId: this.selectedBranchId, nurseryId: siteId } });
                } else {
                    this.$router.push({ name: 'OutplantSite', params: { branchId: this.selectedBranchId, outplantSiteId: siteId } });
                }
            }
        },
        changeStructure(structureId) {
            // Only navigate if the selected structure is different from the current route
            if (structureId !== this.$route.params.structureId) {
                this.$router.push({
                    name: 'Structure',
                    params: {
                        branchId: this.selectedBranchId,
                        nurseryId: this.selectedSiteId,
                        structureId
                    }
                });
            }
        },
        changeCell(cellId) {
            // Only navigate if the selected cell is different from the current route
            if (cellId !== this.$route.params.cellId) {
                this.$router.push({
                    name: 'OutplantCell',
                    params: {
                        branchId: this.selectedBranchId,
                        outplantSiteId: this.selectedSiteId,
                        cellId: cellId,
                    }
                });
            }
        },
        changeDevice(deviceId) {
            if (deviceId !== this.$route.params.deviceId) {
                this.$router.push({ 
                    name: 'Device', 
                    params: { deviceId } 
                });
            }
        },
        updateFromRoute() {
            const { branchId, nurseryId, outplantSiteId, structureId, cellId, deviceId } = this.$route.params;

            this.resetSelections();

            if (this.isDevicesRoute) {
                this.fetchDevices();
                this.selectedDeviceId = deviceId || '';
            } else if (branchId) {
                this.selectedBranchId = branchId;
                this.fetchSites();

                if (nurseryId || outplantSiteId) {
                    this.selectedSiteId = nurseryId || outplantSiteId;
                    nurseryId ? this.fetchStructures() : this.fetchCells();

                    this.selectedStructureId = structureId || '';
                    this.selectedCellId = cellId || '';
                }
            }
        },

        resetSelections() {
            this.selectedBranchId = '';
            this.selectedSiteId = '';
            this.selectedStructureId = '';
            this.selectedCellId = '';
            this.sites = [];
            this.structures = [];
            this.cells = [];
        },
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        async logout() {
            const auth = getAuth();
            try {
                await signOut(auth);
                this.$router.push('/login');
            } catch (error) {
                console.error("Logout failed: ", error.message);
            }
        },
        reset() {
            this.$router.push('/');
        },
        toggleSpecialDropdown(event) {
            event.stopPropagation();
            this.showSpecialDropdown = !this.showSpecialDropdown;
            if (this.showSpecialDropdown) {
                document.addEventListener('click', this.closeSpecialDropdown);
            } else {
                document.removeEventListener('click', this.closeSpecialDropdown);
            }
        },
        closeSpecialDropdown() {
            this.showSpecialDropdown = false;
            document.removeEventListener('click', this.closeSpecialDropdown);
        },
        navigateToCorals() {
            if (this.$route.name !== 'ManageCorals') {
                this.$router.push({ name: 'ManageCorals' });
            }
            this.resetSelections();
            this.toggleDropdown();
        },
        navigateToFish() {
            if (this.$route.name !== 'ManageFish' && this.selectedBranchId) {
                this.$router.push({ name: 'ManageFish', params: { branchId: this.selectedBranchId } });
            }
            this.toggleDropdown();
        },
        navigateToDevices(fromDropdown = false) {
            if (this.$route.name !== 'Devices') {
                this.$router.push({ name: 'Devices' });
            }
            this.resetSelections();
            if (fromDropdown) {
                this.toggleDropdown();
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeSpecialDropdown);
    },
}
</script>