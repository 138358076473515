<template>
    <div class="bg-neutral-800 rounded-lg shadow-md text-white flex flex-col cursor-pointer hover:shadow-lg transition-shadow duration-300 min-h-[400px] mt-4 relative">
        <div :class="['flex', 'flex-grow', isMobile ? 'flex-col h-[600px]' : 'flex-row']">
            <div :class="[
                isMobile ? 'w-full' : 'w-1/2',
                'flex',
                'flex-col',
                'justify-between',
                'p-4',
            ]">
                <div class="grid grid-cols-1 gap-4">
                    <div class="bg-black rounded-lg p-2">
                        <p class="text-xl font-black font-lato">{{ formattedArea }}</p>
                        <p class="text-sm text-neutral-400">Control Area</p>
                    </div>
                    
                    <div v-if="site.outplantSiteID" class="bg-black rounded-lg p-2 cursor-pointer hover:bg-neutral-900" @click="navigateToOutplantSite">
                        <p class="text-xl font-black font-lato flex items-center">
                            <span class="flex-grow">Linked Outplant Site</span>
                            <i class="mdi mdi-arrow-right"></i>
                        </p>
                        <p class="text-sm text-neutral-400">{{ site.name.replace(' Control', '') }}</p>
                    </div>

                    <div v-if="site.reefos_id" class="bg-black rounded-lg p-2 cursor-pointer hover:bg-neutral-900" @click="navigateToDevice">
                        <p class="text-xl font-black font-lato flex items-center">
                            <span class="flex-grow">ReefOS Device</span>
                            <i class="mdi mdi-arrow-right"></i>
                        </p>
                        <p class="text-sm text-neutral-400">{{ site.reefos_id }}</p>
                    </div>
                </div>
            </div>
            <div :class="['z-10', isMobile ? 'w-full h-full mt-4 relative' : 'w-1/2 pl-4', 'relative']">
                <div :class="[
                    isMobile ? 'rounded-bl-lg rounded-br-lg' : 'rounded-tr-lg rounded-br-lg',
                ]" id="control-map"></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { polygon, area } from '@turf/turf';

export default {
    name: 'ControlSiteOverview',
    props: {
        site: {
            type: Object,
            required: true,
        },
        devices: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            map: null,
            polygonArea: 0,
            polygonLayer: null,
            deviceMarkers: [],
        };
    },
    computed: {
        formattedArea() {
            if (this.polygonArea > 100000) {
                return Number((this.polygonArea * 1e-6).toFixed(1)).toLocaleString() + ' km²';
            }
            return Number(this.polygonArea.toFixed(0)).toLocaleString() + ' m²';
        },
        isMobile() {
            return window.innerWidth < 768;
        }
    },
    mounted() {
        this.initMap();
    },
    watch: {
        site() {
            this.initMap();
        },
        devices() {
            this.plotDevices();
        },
    },
    methods: {
        initMap() {
            if (this.map) {
                this.map.remove();
            }

            this.map = L.map('control-map', {
                center: [this.site.perimeter[0].latitude, this.site.perimeter[0].longitude],
                attributionControl: false,
                zoomControl: false,
            });

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.addPolygon();
            this.fitMapBounds();
            if (this.devices.length > 0) this.plotDevices();
        },
        addPolygon() {
            const coordinates = this.site.perimeter.map(point => [point.latitude, point.longitude]);
            this.polygonLayer = L.polygon(coordinates, {
                color: '#ffa500',
                weight: 3,
                opacity: 0.7,
                fillColor: '#ffa500',
                fillOpacity: 0.2
            }).addTo(this.map);
            this.updateArea();
        },
        fitMapBounds() {
            const bounds = L.latLngBounds(this.site.perimeter.map(point => [point.latitude, point.longitude]));
            this.map.fitBounds(bounds, { padding: [100, 100] });
        },
        createPulsingIcon() {
            return L.divIcon({
                className: 'pulsing-icon',
                iconSize: [5, 5],
                iconAnchor: [2.5, 2.5]
            });
        },
        plotDevices() {
            this.deviceMarkers.forEach(marker => marker.remove());
            this.deviceMarkers = [];

            if (this.devices.length > 0) {
                this.devices.forEach(device => {
                    const marker = L.marker([device.location._lat, device.location._long], {
                        icon: this.createPulsingIcon(),
                        zIndexOffset: 1000
                    }).addTo(this.map);

                    marker.bindPopup(`<h3>${device.name || 'Device'}</h3>`);
                    this.deviceMarkers.push(marker);
                });
            }
        },
        updateArea() {
            if (this.polygonLayer) {
                const latlngs = this.polygonLayer.getLatLngs()[0];
                const coordinates = latlngs.map(point => [point.lng, point.lat]);
                if (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
                    coordinates[0][1] !== coordinates[coordinates.length - 1][1]) {
                    coordinates.push(coordinates[0]);
                }
                const poly = polygon([coordinates]);
                this.polygonArea = area(poly);
            }
        },
        navigateToOutplantSite() {
            this.$router.push({
                name: 'OutplantSite',
                params: {
                    branchId: this.$route.params.branchId,
                    outplantSiteId: this.site.outplantSiteID
                }
            });
        },
        navigateToDevice() {
            this.$router.push({
                name: 'Device',
                params: {
                    deviceId: this.site.reefos_id
                }
            });
        }
    },
};
</script>

<style scoped>
#control-map {
    width: 100%;
    height: 100%;
}

.pulsing-icon {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 1);
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 0 rgba(255, 0, 0, 1);
    animation: pulse 5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }

    50% {
        box-shadow: 0 0 15px 15px rgba(255, 0, 0, 0);
        opacity: 0.7;
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
        opacity: 1;
    }
}
</style> 