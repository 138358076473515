<template>
    <div>
        <h2 class="text-2xl font-black font-lato mb-4">Devices
            <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ devices.length }}</span>
        </h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <DeviceCard 
                v-for="device in devices" 
                :key="device.id" 
                :device="device" 
                @click.native="navigateToDevice(device.id)"
            />
        </div>
    </div>
</template>

<script>
import { collection, getDocs } from 'firebase/firestore';
import { db_map } from '../main';
import DeviceCard from './DeviceCard.vue';

export default {
    name: 'Devices',
    components: {
        DeviceCard
    },
    data() {
        return {
            devices: []
        }
    },
    mounted() {
        this.fetchDevices();
    },
    methods: {
        async fetchDevices() {
            try {
                const devicesRef = collection(db_map, 'Cameras');
                const querySnapshot = await getDocs(devicesRef);

                this.devices = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(device => !device.id.startsWith('debug_'));
            } catch (error) {
                console.log('Error fetching devices data:', error);
                this.devices = [];
            }
        },
        navigateToDevice(deviceId) {
            this.$router.push({ 
                name: 'Device', 
                params: { deviceId } 
            });
        }
    }
}
</script> 