var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-neutral-800 rounded-lg shadow-md text-white flex flex-col cursor-pointer hover:shadow-lg transition-shadow duration-300 min-h-[400px] mt-4 relative"},[_c('div',{class:['flex', 'flex-grow', _vm.isMobile ? 'flex-col h-[600px]' : 'flex-row']},[_c('div',{class:[
            _vm.isMobile ? 'w-full' : 'w-1/2',
            'flex',
            'flex-col',
            'justify-between',
            'p-4',
        ]},[_c('div',{staticClass:"grid grid-cols-1 gap-4"},[_c('div',{staticClass:"bg-black rounded-lg p-2"},[_c('p',{staticClass:"text-xl font-black font-lato"},[_vm._v(_vm._s(_vm.formattedArea))]),_c('p',{staticClass:"text-sm text-neutral-400"},[_vm._v("Control Area")])]),(_vm.site.outplantSiteID)?_c('div',{staticClass:"bg-black rounded-lg p-2 cursor-pointer hover:bg-neutral-900",on:{"click":_vm.navigateToOutplantSite}},[_vm._m(0),_c('p',{staticClass:"text-sm text-neutral-400"},[_vm._v(_vm._s(_vm.site.name.replace(' Control', '')))])]):_vm._e(),(_vm.site.reefos_id)?_c('div',{staticClass:"bg-black rounded-lg p-2 cursor-pointer hover:bg-neutral-900",on:{"click":_vm.navigateToDevice}},[_vm._m(1),_c('p',{staticClass:"text-sm text-neutral-400"},[_vm._v(_vm._s(_vm.site.reefos_id))])]):_vm._e()])]),_c('div',{class:['z-10', _vm.isMobile ? 'w-full h-full mt-4 relative' : 'w-1/2 pl-4', 'relative']},[_c('div',{class:[
                _vm.isMobile ? 'rounded-bl-lg rounded-br-lg' : 'rounded-tr-lg rounded-br-lg',
            ],attrs:{"id":"control-map"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-xl font-black font-lato flex items-center"},[_c('span',{staticClass:"flex-grow"},[_vm._v("Linked Outplant Site")]),_c('i',{staticClass:"mdi mdi-arrow-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-xl font-black font-lato flex items-center"},[_c('span',{staticClass:"flex-grow"},[_vm._v("ReefOS Device")]),_c('i',{staticClass:"mdi mdi-arrow-right"})])
}]

export { render, staticRenderFns }