<template>
    <div class="bg-black rounded-lg p-4">
        <!-- Hidden file input -->
        <input 
            type="file"
            ref="fileInput"
            class="hidden"
            accept="image/*"
            @change="handleFileSelect"
        >
        
        <!-- Header with Add Image and Close buttons -->
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl">Nursery Photos</h2>
            <div class="flex space-x-2">
                <button 
                    @click="$emit('close')" 
                    class="px-4 py-2 bg-gray-500 rounded text-white hover:bg-gray-600 transition-colors"
                >
                    Close
                </button>
                <button 
                    @click="triggerFileInput" 
                    class="px-4 py-2 bg-[#27bdf4] rounded text-white hover:bg-[#1da8dc] transition-colors"
                >
                    Add Image
                </button>
            </div>
        </div>
        
        <!-- Existing Photos Grid -->
        <div v-if="nursery && nursery.image_urls && nursery.image_urls.length > 0" class="mb-4">
            <div class="grid gap-4" style="grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));">
                <div v-for="(url, index) in nursery.image_urls" 
                     :key="index"
                     class="relative group">
                    <img :src="url"
                         class="w-full h-[200px] object-cover rounded-lg"
                         :alt="`Nursery image ${index + 1}`">
                    
                    <!-- Controls Overlay -->
                    <div class="absolute inset-0 rounded-lg">
                        <!-- Star button (top left) -->
                        <button @click="setAsCover(url)" 
                                class="absolute top-1 left-1 p-1 rounded-full hover:bg-black hover:bg-opacity-30 transition-colors z-10"
                                :title="nursery.cover_image === url ? 'Current cover photo' : 'Set as cover photo'">
                            <i class="mdi text-xl"
                               :class="[
                                   nursery.cover_image === url 
                                   ? 'mdi-star text-yellow-400' 
                                   : 'mdi-star-outline text-white opacity-75 group-hover:opacity-100'
                               ]">
                            </i>
                        </button>
                        
                        <!-- Download button (bottom right) -->
                        <button @click="downloadImage(url)" 
                                class="absolute bottom-1 right-1 p-1 rounded-full hover:bg-black hover:bg-opacity-30 transition-colors z-10"
                                title="Download image">
                            <i class="mdi mdi-download text-white opacity-75 group-hover:opacity-100"></i>
                        </button>
                        
                        <!-- Delete button (top right) -->
                        <button @click="deletePhoto(url)" 
                                class="absolute top-1 right-1 p-1 rounded-full hover:bg-black hover:bg-opacity-30 transition-colors z-10">
                            <i class="mdi mdi-delete text-white opacity-75 group-hover:opacity-100"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- No Photos Message -->
        <div v-else-if="nursery && (!nursery.image_urls || nursery.image_urls.length === 0)" 
             class="text-center text-gray-400 mb-4">
            No photos added yet
        </div>

        <!-- Loading State -->
        <div v-else class="text-center text-gray-400 mb-4">
            Loading...
        </div>

        <!-- Upload New Photo -->
        <div class="mt-4" v-if="selectedImage">
            <div class="space-y-4">
                <img 
                    :src="selectedImage" 
                    alt="Selected photo" 
                    class="w-full max-h-[600px] rounded-lg object-contain"
                >
                <div class="flex justify-end space-x-2">
                    <button 
                        @click="cancelImage" 
                        class="px-4 py-2 bg-gray-500 rounded text-white hover:bg-gray-600 transition-colors"
                    >
                        Cancel
                    </button>
                    <button 
                        @click="saveImage" 
                        class="px-4 py-2 bg-[#27bdf4] rounded text-white hover:bg-[#1da8dc] transition-colors"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { EventBus } from '@/event-bus.js';

export default {
    name: 'NurseryEditPhotos',
    props: {
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        },
        nursery: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedImage: null,
            imageFile: null,
        };
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        async handleFileSelect(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                try {
                    // Show initial loading toast
                    EventBus.$emit('show-toast', {
                        message: 'Uploading image...',
                        type: 'primary',
                        loading: true
                    });

                    const storage = getStorage();
                    const imagePath = `coral-gardeners/${this.branchId}/images/nurseries/${this.nurseryId}/${Date.now()}_${file.name}`;
                    const imageRef = storageRef(storage, imagePath);

                    // Use uploadBytesResumable and monitor progress
                    const uploadTask = uploadBytesResumable(imageRef, file);
                    
                    // Monitor upload progress
                    uploadTask.on(
                        'state_changed',
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            EventBus.$emit('show-toast', {
                                message: `Uploading: ${Math.round(progress)}%`,
                                type: 'primary',
                                loading: true
                            });
                        },
                        (error) => {
                            console.error('Upload error:', error);
                            EventBus.$emit('show-toast', {
                                message: 'Error uploading image',
                                type: 'error',
                                duration: 3000
                            });
                        }
                    );

                    // Wait for upload to complete
                    await uploadTask;
                    const downloadURL = await getDownloadURL(imageRef);

                    const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                    await updateDoc(nurseryRef, {
                        image_urls: arrayUnion(downloadURL)
                    });

                    // Reset the file input
                    this.$refs.fileInput.value = '';
                    this.$emit('update');

                    // Show success toast
                    EventBus.$emit('show-toast', {
                        message: 'Image uploaded successfully!',
                        type: 'success',
                        duration: 3000
                    });

                } catch (error) {
                    console.error('Error uploading image:', error);
                    EventBus.$emit('show-toast', {
                        message: 'Error uploading image',
                        type: 'error',
                        duration: 3000
                    });
                }
            }
        },
        handleDrop(event) {
            const file = event.dataTransfer.files[0];
            if (file && file.type.startsWith('image/')) {
                this.imageFile = file;
                this.previewImage(file);
            }
        },
        previewImage(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.selectedImage = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        cancelImage() {
            this.selectedImage = null;
            this.imageFile = null;
            this.$refs.fileInput.value = '';
        },
        async saveImage() {
            if (!this.imageFile) return;

            try {
                const storage = getStorage();
                const imagePath = `coral-gardeners/${this.branchId}/images/nurseries/${this.nurseryId}/${Date.now()}_${this.imageFile.name}`;
                const imageRef = storageRef(storage, imagePath);

                const snapshot = await uploadBytes(imageRef, this.imageFile);
                const downloadURL = await getDownloadURL(snapshot.ref);

                const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                await updateDoc(nurseryRef, {
                    image_urls: arrayUnion(downloadURL)
                });

                this.cancelImage();
                this.$emit('update');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        },
        async deletePhoto(url) {
            if (!confirm('Are you sure you want to delete this photo?')) return;

            try {
                EventBus.$emit('show-toast', {
                    message: 'Deleting image...',
                    type: 'primary',
                    loading: true
                });

                const storage = getStorage();
                const imageRef = storageRef(storage, url);
                await deleteObject(imageRef);

                const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                await updateDoc(nurseryRef, {
                    image_urls: arrayRemove(url)
                });

                // Immediately update local data
                if (this.nursery) {
                    this.nursery.image_urls = this.nursery.image_urls.filter(imgUrl => imgUrl !== url);
                    if (this.nursery.cover_image === url) {
                        this.nursery.cover_image = null;
                        await updateDoc(nurseryRef, {
                            cover_image: null
                        });
                    }
                }

                // Show success toast after local update
                EventBus.$emit('show-toast', {
                    message: 'Image deleted successfully',
                    type: 'success',
                    duration: 3000
                });

                // Still emit update for parent component
                this.$emit('update');

            } catch (error) {
                console.error('Error deleting image:', error);
                EventBus.$emit('show-toast', {
                    message: 'Error deleting image',
                    type: 'error',
                    duration: 3000
                });
            }
        },
        async setAsCover(url) {
            try {
                EventBus.$emit('show-toast', {
                    message: 'Updating cover image...',
                    type: 'primary',
                    loading: true
                });

                const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
                await updateDoc(nurseryRef, {
                    cover_image: url
                });

                // Immediately update local data
                if (this.nursery) {
                    this.nursery.cover_image = url;
                }

                EventBus.$emit('show-toast', {
                    message: 'Cover image updated successfully',
                    type: 'success',
                    duration: 3000
                });

                this.$emit('update');
            } catch (error) {
                console.error('Error setting cover image:', error);
                EventBus.$emit('show-toast', {
                    message: 'Error updating cover image',
                    type: 'error',
                    duration: 3000
                });
            }
        },
        async downloadImage(url) {
            try {
                const response = await fetch(url);
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = `nursery-image-${Date.now()}.jpg`; // Default filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            } catch (error) {
                console.error('Error downloading image:', error);
                EventBus.$emit('show-toast', {
                    message: 'Error downloading image',
                    type: 'error',
                    duration: 3000
                });
            }
        }
    }
};
</script> 