<template>
    <div v-if="controlSite">
        <div class="flex items-center mb-4 ml-1">
            <h1 class="text-2xl font-black font-lato mr-2">{{ controlSite.name }}</h1>
            <div class="relative">
                <button @click="toggleMenu" class="text-white hover:text-gray-300 transition-colors duration-300">
                    <i class="mdi mdi-dots-vertical text-xl"></i>
                </button>
                <div v-if="showMenu" class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-black border border-gray-600 z-10">
                    <div class="py-1 text-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <a href="#" class="block px-4 py-2 text-sm text-white hover:bg-gray-800" role="menuitem" @click.prevent="editSite">
                            <i class="mdi mdi-pencil mr-2"></i>Edit
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ControlSiteOverview :site="controlSite" :devices="devices" />
    </div>
</template>

<script>
import { db_resto } from '../main';
import { db_map } from '../main';
import { doc, getDoc } from 'firebase/firestore';
import ControlSiteOverview from './ControlSiteOverview.vue';

export default {
    name: 'ControlSite',
    components: {
        ControlSiteOverview
    },
    props: {
        branchId: String,
        controlSiteId: String,
    },
    data() {
        return {
            controlSite: null,
            devices: [],
            showMenu: false,
        };
    },
    mounted() {
        this.fetchControlData();
    },
    watch: {
        '$route': {
            immediate: true,
            handler() {
                this.fetchControlData();
            }
        }
    },
    methods: {
        async fetchControlData() {
            try {
                const controlSiteRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/ControlSites/${this.controlSiteId}`);
                const controlSiteDoc = await getDoc(controlSiteRef);

                if (controlSiteDoc.exists()) {
                    this.controlSite = { id: controlSiteDoc.id, ...controlSiteDoc.data() };
                    if (this.controlSite.reefos_id) this.getDeviceInfo(this.controlSite.reefos_id);
                } else {
                    console.log('No such control site!');
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        async getDeviceInfo(reefosId) {
            try {
                const deviceRef = doc(db_map, `Cameras/${reefosId}`);
                const deviceDoc = await getDoc(deviceRef);

                if (deviceDoc.exists()) {
                    this.devices.push({ id: deviceDoc.id, ...deviceDoc.data() });
                } else {
                    console.log('No such device!');
                }
            } catch (error) {
                console.error("Error fetching device data:", error);
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        editSite() {
            // TODO: Implement edit functionality
            this.showMenu = false;
        }
    }
}
</script>

<style scoped>

</style>