import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.min.css';
import './assets/styles/index.css'
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyAcwkXtS7ab1Pf_In184d34DEerZnhz48A",
  authDomain: "cg-reef-manager.firebaseapp.com",
  projectId: "cg-reef-manager",
  storageBucket: "cg-reef-manager.firebasestorage.app",
  messagingSenderId: "432819121014",
  appId: "1:432819121014:web:5de5915f0f3da65eb46857",
  measurementId: "G-QSY3FKT4DV"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const firebaseConfig_resto = {
  apiKey: "AIzaSyBc3QUNddqjuuDhu6CHqV6kIq2ALQWaHMk",
  authDomain: "restoration-ios.firebaseapp.com",
  projectId: "restoration-ios",
  storageBucket: "restoration-ios.appspot.com",
  messagingSenderId: "96749053095",
  appId: "1:96749053095:web:1c5163ac423f7e1185e782",
  measurementId: "G-R02354DHWZ"
};

const app_resto = initializeApp(firebaseConfig_resto, "resto");
export const db_resto = getFirestore(app_resto);

const firebaseConfig_map = {
  apiKey: "AIzaSyDByYxdnctmqQdd3VtjBV4NMAPe7O5_f64",
  authDomain: "cg-reefmap.firebaseapp.com",
  projectId: "cg-reefmap",
  storageBucket: "cg-reefmap.appspot.com",
  messagingSenderId: "761959520605",
  appId: "1:761959520605:web:d28c911b8a9d066aec30b4"
};

const app_map = initializeApp(firebaseConfig_map, "map");
export const db_map = getFirestore(app_map)


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
