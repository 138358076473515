<template>
    <div
        class="mb-4 bg-neutral-800 rounded-lg shadow-md text-white flex flex-col lg:flex-row hover:shadow-lg transition-shadow duration-300">
        <!-- Left side: Form -->
        <div class="w-full lg:w-1/2 p-4 flex flex-col">
            <div class="grid grid-cols-2 gap-4 mb-4">
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="name" v-model="editedNursery.name" type="text"
                        class="text-xl font-black font-lato bg-transparent" placeholder="Nursery Name">
                    <label for="name" class="text-sm text-neutral-400">Name</label>
                </div>
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="created" v-model="editedNursery.created" type="date"
                        class="text-xl font-black font-lato bg-transparent">
                    <label for="created" class="text-sm text-neutral-400">Created</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mb-4">
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <select id="stage" v-model="editedNursery.stage"
                        class="text-xl font-black font-lato bg-transparent">
                        <option value="seeding">Seeding</option>
                        <option value="growing">Growing</option>
                        <option value="planting">Planting</option>
                    </select>
                    <label for="stage" class="text-sm text-neutral-400">Stage</label>
                </div>
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="seeding" v-model="editedNursery.goals.seeding" type="number"
                        class="text-xl font-black font-lato bg-transparent" :placeholder="goalPlaceholders.seeding">
                    <label for="seeding" class="text-sm text-neutral-400">{{ stageLabels.seeding }}</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mb-4">
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="growing" v-model="editedNursery.goals.growing" type="number"
                        class="text-xl font-black font-lato bg-transparent" :placeholder="goalPlaceholders.growing">
                    <label for="growing" class="text-sm text-neutral-400">{{ stageLabels.growing }}</label>
                </div>
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="planting" v-model="editedNursery.goals.planting" type="number"
                        class="text-xl font-black font-lato bg-transparent" :placeholder="goalPlaceholders.planting">
                    <label for="planting" class="text-sm text-neutral-400">{{ stageLabels.planting }}</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mb-4">
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="latitude" v-model.number="editedNursery.geoLocation.latitude" type="number"
                        step="0.000001" class="text-xl font-black font-lato bg-transparent" placeholder="Latitude">
                    <label for="latitude" class="text-sm text-neutral-400">Latitude</label>
                </div>
                <div class="flex flex-col bg-black p-2 rounded-lg">
                    <input id="longitude" v-model.number="editedNursery.geoLocation.longitude" type="number"
                        step="0.000001" class="text-xl font-black font-lato bg-transparent" placeholder="Longitude">
                    <label for="longitude" class="text-sm text-neutral-400">Longitude</label>
                </div>
            </div>

            <div class="flex justify-end mt-auto">
                <button @click="cancel" class="mr-2 px-4 py-2 bg-gray-500 rounded">Cancel</button>
                <button @click="save" class="px-4 py-2 bg-[#27bdf4] rounded">Save</button>
            </div>
        </div>

        <!-- Right side: Map -->
        <div class="w-full lg:w-1/2 h-64 lg:h-auto">
            <div ref="map" class="w-full h-full rounded-tr-lg rounded-br-lg"></div>
        </div>
    </div>
</template>

<script>
import { db_resto } from '../main';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
    name: 'NurseryEdit',
    props: {
        branchId: {
            type: String,
            required: true
        },
        nurseryId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            nursery: null,
            editedNursery: {
                name: '',
                created: '',
                stage: '',
                goals: {
                    seeding: null,
                    growing: null,
                    planting: null
                },
                geoLocation: {
                    latitude: null,
                    longitude: null
                }
            },
            stages: ['seeding', 'growing', 'planting'],
            stageLabels: {
                seeding: 'Seeding Goal (corals)',
                growing: 'Growing Goal (years)',
                planting: 'Planting Goal (%)'
            },
            goalPlaceholders: {
                seeding: 'Number of corals',
                growing: 'Number of years',
                planting: 'Percentage of corals'
            },
            map: null,
            marker: null,
            mapInitialized: false
        };
    },
    mounted() {
        this.getNurseryData();
    },
    watch: {
        'editedNursery.geoLocation': {
            handler(newVal) {
                if (this.mapInitialized) {
                    this.updateMarkerPosition(newVal.latitude, newVal.longitude);
                }
            },
            deep: true
        }
    },
    methods: {
        getNurseryData() {
            const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
            getDoc(nurseryRef).then((doc) => {
                if (doc.exists()) {
                    this.nursery = doc.data();
                    this.editedNursery = {
                        name: this.nursery.name || '',
                        created: this.formatDate(this.nursery.created),
                        stage: this.nursery.stage || '',
                        goals: {
                            seeding: this.nursery.goals?.seeding ?? null,
                            growing: this.nursery.goals?.growing ?? null,
                            planting: this.nursery.goals?.planting ?? null
                        },
                        geoLocation: {
                            latitude: this.nursery.geoLocation?.latitude ?? null,
                            longitude: this.nursery.geoLocation?.longitude ?? null
                        }
                    };
                    this.$nextTick(() => {
                        this.initMap();
                    });
                } else {
                    console.log('No such document!');
                }
            }).catch((error) => {
                console.log('Error getting document:', error);
            });
        },
        initMap() {
            if (this.map) {
                this.map.remove();
            }
            this.map = L.map(this.$refs.map, {
                center: [this.nursery.geoLocation.latitude || 0, this.nursery.geoLocation.longitude || 0],
                zoom: 13,
                attributionControl: false,
                zoomControl: false,
            });

            const mapboxAccessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + mapboxAccessToken, {
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.map);

            this.updateMarkerPosition(this.nursery.geoLocation.latitude, this.nursery.geoLocation.longitude);



        },
        updateMarkerPosition(lat, lng) {
            if (!this.map) return;

            if (lat && lng) {
                const newLatLng = L.latLng(lat, lng);
                if (this.marker) {
                    this.marker.setLatLng(newLatLng);
                } else {
                    this.marker = L.marker(newLatLng, {
                        icon: this.createCustomIcon('orange'),
                        draggable: true
                    }).addTo(this.map);

                    this.marker.on('dragend', (event) => {
                        let position = event.target.getLatLng();
                        this.editedNursery.geoLocation.latitude = position.lat;
                        this.editedNursery.geoLocation.longitude = position.lng;
                    });
                }
                this.map.setView(newLatLng, 16);
            } else if (this.marker) {
                this.map.removeLayer(this.marker);
                this.marker = null;
                this.map.setView([0, 0], 2);
            }
        },
        createCustomIcon(color) {
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<div style="background-color:${color};width:10px;height:10px;border-radius:50%;"></div>`,
                iconSize: [10, 10],
                iconAnchor: [5, 5]
            });
        },
        formatDate(timestamp) {
            if (!timestamp) return '';
            const date = timestamp.toDate();
            return date.toISOString().split('T')[0];
        },
        cancel() {
            this.editedNursery = {
                name: this.nursery?.name || '',
                created: this.formatDate(this.nursery?.created),
                stage: this.nursery?.stage || '',
                goals: {
                    seeding: this.nursery?.goals?.seeding ?? null,
                    growing: this.nursery?.goals?.growing ?? null,
                    planting: this.nursery?.goals?.planting ?? null
                },
                geoLocation: {
                    latitude: this.nursery?.geoLocation?.latitude ?? null,
                    longitude: this.nursery?.geoLocation?.longitude ?? null
                }
            };
            this.updateMarkerPosition(this.editedNursery.geoLocation.latitude, this.editedNursery.geoLocation.longitude);
            this.$emit('update');
        },
        save() {
            const nurseryRef = doc(db_resto, `Orgs/coral-gardeners/Branches/${this.branchId}/Nurseries/${this.nurseryId}`);
            updateDoc(nurseryRef, {
                name: this.editedNursery.name,
                created: new Date(this.editedNursery.created),
                stage: this.editedNursery.stage,
                goals: this.editedNursery.goals,
                geoLocation: this.editedNursery.geoLocation
            }).then(() => {
                console.log('Document successfully updated!');
                this.nursery = JSON.parse(JSON.stringify(this.editedNursery));
                this.$emit('update');
            }).catch((error) => {
                console.error('Error updating document: ', error);
            });
        }
    }
};
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

select {
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position: right 5px top 50%;
    padding-right: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* input[type="number"] {
    -moz-appearance: textfield;
} */
</style>