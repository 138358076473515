<template>
    <div>
        <h2 class="text-2xl font-black font-lato mb-4">Branches
            <span class="rounded-full text-lg bg-white text-black px-2 ml-2">{{ branches.length }}</span>
        </h2>
        <div class="grid">
            <BranchCard v-for="branch in branches" :key="branch.id" :branch="branch" />
        </div>

        <!-- <h2 class="text-2xl font-black font-lato mb-4">Links</h2>
        <div class="grid">
            <AppCard v-for="app in internalApps" :key="app.name" :app="app" @click.native="navigateTo(app.name)" />
        </div> -->

        <!-- <h2 class="text-2xl font-black font-lato mb-4">Apps</h2>
        <div class="grid">
            <AppCard v-for="app in externalApps" :key="app.name" :app="app" @click.native="navigateToExternal(app.url)"/>
        </div> -->

        <!-- <h2 class="text-2xl font-black font-lato mb-4">Devices</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <DeviceCard v-for="device in devices" :key="device.id" :device="device" />
        </div> -->

        <!-- <div v-if="Object.keys(global).length > 0 && time_series.length > 0">
            <h2 class="text-2xl font-black font-lato mb-4 mt-4">Global</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                <TotalsCard :global="global" />
                <SurvivalCard v-if="global && branches.length > 0" :global="global" :branches="branches" />
                <GenusCard :global="global" />
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <CoralCard :time_series="time_series" />
                <MapCard :global="global" :branches="branches" />
                <TempCard />
                <CamCard />
            </div>
        </div> -->
    </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { db_map } from '../main';
import { db_resto } from '../main';
import { collection, getDocs } from 'firebase/firestore';
import BranchCard from './BranchCard.vue';
import TotalsCard from './global/TotalsCard.vue';
import SurvivalCard from './global/SurvivalCard.vue';
import GenusCard from './global/GenusCard.vue';
import CoralCard from './global/CoralCard.vue';
import TempCard from './global/TempCard.vue';
import MapCard from './global/MapCard.vue';
import CamCard from './global/CamCard.vue';
import AppCard from './AppCard.vue';
import DeviceCard from './DeviceCard.vue';

export default {
    name: 'Global',
    components: {
        BranchCard,
        TotalsCard,
        SurvivalCard,
        GenusCard,
        CoralCard,
        TempCard,
        MapCard,
        CamCard,
        AppCard,
        DeviceCard,
    },
    data() {
        return {
            userName: '',
            global: [],
            branches: [],
            time_series: [],
            externalApps: [
                { name: 'Photogrammetry Toolkit', img: 'photogrammetry.png', url: 'https://photogrammetry.coralgardeners.org' },
                { name: 'Data Explorer', img: 'data_explorer.png', url: 'https://data.coralgardeners.org' },
                { name: 'Impact Dashboard', img: 'dashboard.png', url: 'https://dashboard.coralgardeners.org' },
                { name: 'Impact Portal', img: 'impactportal.png', url: 'https://impactportal.coralgardeners.org' },
                { name: 'Reefmap', img: 'reefmap.png', url: 'https://maps.coralgardeners.org' },
                { name: 'Community Science', img: 'community_science.png', url: 'https://community.coralgardeners.org' },
            ],
            internalApps: [
                { name: 'Adoptions', img: 'adoptions.png', route: '/adoptions' },
                { name: 'Livestreams', img: 'livestreams.png', route: '/livestreams' },
            ],
            devices: [],
        };
    },
    mounted() {
        this.fetchUserName();
        this.fetchGlobal();
        this.fetchBranches();
        this.fetchTimeSeries();
        this.fetchDevices();
    },
    computed: {
        isMobile() {
            return window.innerWidth < 768;
        },
    },
    methods: {
        async fetchGlobal() {
            try {
                const response = await fetch(`https://data.coralgardeners.org/data/global?org=coral-gardeners&pwd=showmethedata`);
                const responseData = await response.json();
                this.global = responseData.global;

            } catch (error) {
                console.log('Error fetching data:', error);
                this.global = {}; // Set to empty object in case of error
            }
        },
        async fetchBranches() {
            try {
                // Fetch branches from Firestore
                const branchesRef = collection(db_resto, 'Orgs', 'coral-gardeners', 'Branches');
                const querySnapshot = await getDocs(branchesRef);
                const firestoreBranches = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(branch => branch.name !== 'Sandbox');

                // Fetch branches data from API
                let apiData = [];
                try {
                    const response = await fetch('https://data.coralgardeners.org/data/branches?org=coral-gardeners&pwd=showmethedata');
                    const responseData = await response.json();
                    apiData = responseData.branches.filter(branch => branch.Branch !== 'Sandbox');
                } catch (apiError) {
                    console.log('Error fetching data from API:', apiError);
                }

                // Merge Firestore and API data
                this.branches = firestoreBranches.map(branch => {
                    const branchData = apiData.find(b => b.branchID === branch.id) || null;
                    return { ...branch, data: branchData };
                });
            } catch (error) {
                console.log('Error fetching branches data:', error);
                this.branches = [];
            }
        },
        async fetchTimeSeries() {
            try {
                const response = await fetch('https://data.coralgardeners.org/data/byyear?org=coral-gardeners&pwd=showmethedata');
                const responseData = await response.json();
                this.time_series = responseData.byyear;
            } catch (error) {
                console.log('Error fetching time series data:', error);
                this.time_series = [];
            }
        },
         async fetchDevices() {
            try {
                const devicesRef = collection(db_map, 'Cameras');
                const querySnapshot = await getDocs(devicesRef);

                this.devices = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(device => !device.id.startsWith('debug_'));
            } catch (error) {
                console.log('Error fetching devices data:', error);
                this.devices = [];
            }
        },
        async fetchUserName() {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                this.userName = user.displayName.split(' ')[0] || user.email.split('@')[0];
            }
        },
        navigateTo(name) {
            this.$router.push({ name: name });
        },
        navigateToExternal(url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        },
    },
}
</script>