<template>
    <div @click="navigateToDevice"
        class="relative bg-cover bg-center rounded-lg shadow-md text-white p-4 flex flex-col min-w-[200px] min-h-[150px] cursor-pointer hover:shadow-lg transition-shadow duration-300 overflow-hidden"
        :style="{ backgroundImage: `url(${device.zed})` }">
        <div class="absolute inset-0 bg-gradient-to-b from-black to-transparent opacity-0"></div>
        <h3 class="relative z-10 text-xl font-black font-lato mb-2">{{ device.id }}</h3>
        <div class="absolute bottom-2 right-2 flex items-center text-xs">
            <i class="mdi mdi-update mr-1"></i>
            <span>{{ formattedTime }}</span>
        </div>
        <div class="absolute top-2 right-2 flex items-center text-xs">
            <div :class="getStatusColorClass(latestTimestamp)" class="w-2 h-2 rounded-full mr-1"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeviceCard',
    props: ['device'],
    computed: {
        formattedTime() {
            return new Date(this.device.zed_time.toDate()).toLocaleString();
        },
        latestTimestamp() {
            if (!this.device || !this.device.sensors) return null;
            const timestamps = Object.values(this.device.sensors)
                .filter(value => value && value.seconds)
                .map(value => value.seconds * 1000);
            return Math.max(...timestamps);
        },
        isOnline() {
            if (!this.latestTimestamp) return false;
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            return this.latestTimestamp >= today;
        }
    },
    methods: {
        getStatusColorClass(timestamp) {
            if (!timestamp) return 'bg-gray-400';
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            const twoDaysAgo = new Date(today);
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

            if (timestamp >= today) return 'bg-green-500';
            if (timestamp >= yesterday) return 'bg-yellow-500';
            if (timestamp >= twoDaysAgo) return 'bg-red-500';
            return 'bg-red-500';
        },
        navigateToDevice() {
            this.$router.push({
                name: 'Device',
                params: {
                    deviceId: this.device.id
                }
            });
        }
    }
}
</script>