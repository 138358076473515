<template>
  <div class="bg-black rounded-lg shadow-md text-white p-4 flex flex-col h-[350px]">
    <div class="flex items-center mb-2 justify-between">
      <div class="flex items-center space-x-2">
        <i class="mdi mdi-clock-outline text-2xl"></i>
        <span class="text-sm">Uptime: <span class="font-bold">{{ uptimePercentage }}%</span></span>
      </div>
      <div class="flex items-center">
        <select v-model="selectedTimespan" @change="handleTimespanChange" class="bg-neutral-700 text-white py-2 px-4 rounded text-xs">
          <option value="1w">1 week</option>
          <option value="1m">1 month</option>
          <option value="3m">3 months</option>
          <option value="1y">1 year</option>
        </select>
      </div>
    </div>
    <div class="flex-grow relative">
      <canvas ref="chartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { InfluxDB } from '@influxdata/influxdb-client';
import 'chartjs-adapter-date-fns';

const token = '1Mb6JnkdlVyRrVb7JaccrOOgt-7jWgkQNIrpU_iJtGm_6CNeLt_WlABvfMfKR1JHzSC7nZb75HJraBRxAkYhpQ==';
const client = new InfluxDB({ url: 'https://us-central1-1.gcp.cloud2.influxdata.com', token: token });

export default {
  name: 'DeviceUptime',
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      selectedTimespan: '3m',
      uptimePercentage: '--',
    };
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    handleTimespanChange() {
      this.updateChart();
    },
    async fetchData() {
      const queryApi = client.getQueryApi('ReefOS');
      const fluxQuery = this.buildFluxQuery();

      return new Promise((resolve, reject) => {
        const result = [];
        queryApi.queryRows(fluxQuery, {
          next: (row, tableMeta) => {
            const o = tableMeta.toObject(row);
            // If we have any data point in the hour window, consider it "up" (1)
            result.push({
              value: o._value > 0 ? 1 : 0,
              timestamp: new Date(o._time),
            });
          },
          error: (error) => reject(error),
          complete: () => resolve(result),
        });
      });
    },
    buildFluxQuery() {
      let range, aggregateWindow;
      switch (this.selectedTimespan) {
        case '1w':
          range = '(start: -1w)';
          aggregateWindow = '24h';
          break;
        case '1m':
          range = '(start: -1mo)';
          aggregateWindow = '24h';
          break;
        case '3m':
          range = '(start: -3mo)';
          aggregateWindow = '24h';
          break;
        case '1y':
          range = '(start: -1y)';
          aggregateWindow = '24h';
          break;
        default:
          range = '(start: -3mo)';
          aggregateWindow = '24h';
      }

      // Count number of measurements in each window
      return `
        from(bucket: "${this.deviceId}")
        |> range${range}
        |> filter(fn: (r) => r._measurement == "sensors" and r._field == "externalTemperature")
        |> aggregateWindow(every: ${aggregateWindow}, fn: count, createEmpty: true)
        |> yield(name: "count")
      `;
    },
    async updateChart() {
      try {
        const data = await this.fetchData();
        
        // Calculate uptime percentage
        const totalPoints = data.length;
        const upPoints = data.filter(d => d.value === 1).length;
        this.uptimePercentage = totalPoints > 0 
          ? Math.round((upPoints / totalPoints) * 100) 
          : 0;

        const dataset = {
          label: 'Uptime',
          data: data.map(d => ({ x: d.timestamp, y: d.value })),
          borderWidth: 1.5,
          borderColor: '#22c55e',
          backgroundColor: this.getGradient(),
          fill: true,
          stepped: true,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3
        };

        this.createChart([dataset]);
      } catch (error) {
        console.error("Error fetching data:", error);
        this.uptimePercentage = '--';
      }
    },
    createChart(datasets) {
      const ctx = this.$refs.chartCanvas.getContext('2d');

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(ctx, {
        type: 'line',
        data: { datasets },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              mode: 'index',
              intersect: false,
              displayColors: false,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              titleColor: 'white',
              bodyColor: 'white',
              titleFont: { weight: 'bold' },
              bodyFont: { size: 14 },
              padding: 10,
              callbacks: {
                title: (tooltipItems) => {
                  const date = new Date(tooltipItems[0].parsed.x);
                  return date.toLocaleString();
                },
                label: (context) => {
                  return context.parsed.y === 1 ? 'Online' : 'Offline';
                }
              }
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: this.getTimeUnit(),
                displayFormats: {
                  hour: 'HH:mm',
                  day: 'MMM d',
                  week: 'MMM d',
                  month: 'MMM yyyy'
                }
              },
              ticks: { 
                color: 'white',
                maxTicksLimit: 12
              },
            },
            y: {
              min: 0,
              max: 1,
              ticks: {
                color: 'white',
                callback: function(value) {
                  return '';
                }
              },
              grid: {
                display: true,
                color: 'rgba(255, 255, 255, 0.2)',
                borderDash: [4, 4],
              }
            }
          }
        }
      });
    },
    getGradient() {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 250);
      gradient.addColorStop(0, 'rgba(34, 197, 94, 0.5)');  // Green with opacity
      gradient.addColorStop(1, 'rgba(34, 197, 94, 0.1)');
      return gradient;
    },
    getTimeUnit() {
      switch (this.selectedTimespan) {
        case '1w': return 'day';
        case '1m': return 'day';
        case '3m': return 'month';
        case '1y': return 'month';
        default: return 'month';
      }
    }
  }
}
</script>
