import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/components/Login.vue';
import Main from '@/components/Main.vue';
import Global from '@/components/Global.vue';
import Branch from '@/components/Branch.vue';
import Nursery from '@/components/Nursery.vue';
import Structure from '@/components/Structure.vue';
import OutplantSite from '@/components/OutplantSite.vue';
import ControlSite from '@/components/ControlSite.vue';
import OutplantCell from '@/components/OutplantCell.vue';
import Schedule from '@/components/Schedule.vue';
import AddNursery from '@/components/AddNursery.vue';
import AddSite from '@/components/AddSite.vue';
import BranchMap from '@/components/BranchMap.vue';
import Device from '@/components/Device.vue';
import {getAuth, onAuthStateChanged } from 'firebase/auth';
import Adoptions from '@/components/Adoptions.vue';
import Livestreams from '@/components/Livestreams.vue';
import ManageCorals from '@/components/ManageCorals.vue';
import ManageFish from '@/components/ManageFish.vue';
import Devices from '@/components/Devices.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
	  	{
			path: '/',
			component: Main,
			meta: { requiresAuth: true },
			children: [
				{
					path: '',
					name: 'Global',
					component: Global
				},
				{
					path: 'branch/:branchId',
					name: 'Branch',
					component: Branch,
					props: true
				},
				{
					path: 'branch/:branchId/nursery/:nurseryId',
					name: 'Nursery',
					component: Nursery,
					props: true
				},
				{
					path: 'branch/:branchId/nursery/:nurseryId/structure/:structureId',
					name: 'Structure',
					component: Structure,
					props: true
				},
				{
					path: 'branch/:branchId/outplant/:outplantSiteId',
					name: 'OutplantSite',
					component: OutplantSite,
					props: true
				},
				{
					path: 'branch/:branchId/control/:controlSiteId',
					name: 'ControlSite',
					component: ControlSite,
					props: true
				},
				{
					path: 'branch/:branchId/outplant/:outplantSiteId/cell/:cellId',
					name: 'OutplantCell',
					component: OutplantCell,
					props: true
				},
				{
					path: 'branch/:branchId/:type/:siteId/schedule',
					name: 'Schedule',
					component: Schedule,
					props: true
				},
				{
					path: '/branch/:branchId/add-nursery',
					name: 'AddNursery',
					component: AddNursery,
					props: true
				},
				{
					path: '/branch/:branchId/add-site',
					name: 'AddSite',
					component: AddSite,
					props: true
				},
				{
					path: '/branch/:branchId/map',
					name: 'BranchMap',
					component: BranchMap,
					props: true
				},
				{
					path: 'adoptions',
					name: 'Adoptions',
					component: Adoptions
				},
				{
					path: 'livestreams',
					name: 'Livestreams',
					component: Livestreams
				},
				{
					path: '/corals',
					name: 'ManageCorals',
					component: ManageCorals
				},
				{
					path: '/branch/:branchId/fish',
					name: 'ManageFish',
					component: ManageFish,
					props: true
				},
				{
					path: '/devices',
					name: 'Devices',
					component: Devices
				},
				{
					path: '/devices/:deviceId',
					name: 'Device',
					component: Device,
					props: true
				}
			]
		},
	  	{
			path: '/login',
			name: 'Login',
			component: Login,
	  	},
    { 
		path: '*', 
		redirect: '/login' 
    }
  ]
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const auth = getAuth();

	const unsubscribe = onAuthStateChanged(auth, (user) => {
		if (user) {
			// User is signed in
			if (to.path === '/login' && to.query.redirect) {
				// If coming from login page with a redirect query, go to that redirect
				next({ path: decodeURIComponent(to.query.redirect) });
			} else {
				// Otherwise, just proceed
				next();
			}
		} else {
			// User is not signed in
			if (requiresAuth) {
				// If the route requires auth, redirect to login with the intended destination
				next({
					path: '/login',
					query: { redirect: to.fullPath }
				});
			} else {
				// If the route doesn't require auth, just proceed
				next();
			}
		}
		unsubscribe();
	});
});

export default router;

